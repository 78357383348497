import React, { useState, useEffect, createContext, useContext } from 'react'
import { UserContext } from './UserContext'
import api2 from '../helpers/api2'

export const ProGroupsContext = createContext()

export default function ProGroupsProvider (props) {
    const [proGroups, setProGroups] = useState([])
    const { socket } = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    function getProGroups () {
      api2.get('driver-groups')
        .then(({ data }) => {
          setProGroups(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    useEffect(() => getProGroups(), [])

    const P = (p, proGroupsObject) => {
        const temp = [...p]
        temp[temp.findIndex(e => e.id === proGroupsObject.id)] = proGroupsObject
        return temp
    }


    return (
        <ProGroupsContext.Provider
            value={{
                proGroups,
                setProGroups,
                loading,
                setLoading,
            }}>
            {props.children}
        </ProGroupsContext.Provider>
    )
}