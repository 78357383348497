import React, { useState, useEffect, createContext, useContext } from 'react'
import { UserContext } from './UserContext'
import api2 from '../helpers/api2'

export const ContactsContext = createContext()

export default function ContactsProvider (props) {
    const [contacts, setContacts] = useState([])
    const { socket } = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    function getContacts () {
        fetch(process.env.REACT_APP_API_URL + '/contacts?sort[firstName]=asc&limit=400000', { credentials: 'include' })
            .then(res => res.json())
            .then(res => {
                setContacts(res)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }
    //   function getContacts() {
    //   api2.get('/contacts', {
    //     params: {
    //       sort: { fullName: 'asc' },
    //       limit: 10000
    //     }
    //   })
    //     .then(({ data }) => setContacts(data))
    //     .catch((err) => { console.log(err); });
    // }

    useEffect(() => getContacts(), [])

    const C = (c, contactsObject) => {
        const temp = [...c]
        temp[temp.findIndex(e => e.id === contactsObject.id)] = contactsObject
        return temp
    }

    useEffect(() => {
        function update (contactsObject) {
            setContacts(c => C(c, contactsObject))
        }
        function newC (contactsObject) {
            console.log('new customer in socket', contactsObject)
            setContacts(c => [contactsObject, ...c])
        }
        function deleted (id) {
            console.log('new deleted in socket', id)
            setContacts(c => c.filter(e => e.id !== id))
        }

        socket.current.on('customer:new', newC)
        socket.current.on('customer:update', update)
        socket.current.on('customer:deleted', deleted)

        return () => {
            socket.current.off('customer:new', newC)
            socket.current.off('customer:update', update)
            socket.current.off('customer:deleted', deleted)
        }
    }, [])

    return (
        <ContactsContext.Provider
            value={{
                contacts,
                setContacts,
                loading,
                setLoading,
            }}>
            {props.children}
        </ContactsContext.Provider>
    )
}
